<template>
	<el-dialog :title="'处理意见及跟踪情况'" :close-on-click-modal="false" :visible.sync="visible"
			   width="920px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="200px">
			<el-row>
				<el-col :span="12">
					<el-form-item label="发文类型" v-if="type===0">
						<el-tag type="danger">{{getDocumentType(dataForm.fileKind)}}</el-tag>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="发文编号"  v-if="type===0">
						{{dataForm.fileNo}}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="文件主题" >
						{{dataForm.fileTitle}}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="文件内容" >
						{{dataForm.fileContent}}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="处理意见及跟踪情况" prop="handleOptions">
						<el-input class="selItemInput descAreaLg" type="textarea" v-model="dataForm.handleOptions" rows="3" placeholder="请填写审核意见"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary"  @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "superdocument-audit",
		data() {
			return {
				visible: false,
				inline: false,
				documentTypeList: [],
				type: '',
				dataForm: {
					fileId: '',
					handleOptions: '',
					closeFlg: '',
					closeMan: '',
					closeTime: '',
				},
				dataRule: {
					handleOptions: [
						{ required: true, message: "请输入处理意见", trigger: "blur" }
					]
				},
			}
		},
		methods: {
			init(id) {
				this.dataForm = {
					fileId: '',
					handleOptions: '',
					closeFlg: '',
					closeMan: '',
					closeTime: '',
				};
				this.dataForm.fileId = id?id:0;
				this.$nextTick(() => {
					this.visible = true;
					if (this.dataForm.fileId) {
						this.getDetail();
					}
					this.$refs['dataForm'].resetFields();
				});
			},
			getCurrentTime() {
				//获取当前时间并打印
				var _this = this;
				let yy = new Date().getFullYear();
				let mm = new Date().getMonth()+1;
				let dd = new Date().getDate();
				let hh = new Date().getHours();
				let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
				let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
				return yy+'-'+mm+'-'+dd;
			},
			getDetail(){
				this.$http({
					url: this.$store.state.httpUrl + `/business/supervisioncontactinfo/info/${this.dataForm.fileId}`,
					method: "get",
					params: {}
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataForm = {
							fileId: data.body.fileId,
							fileNo: data.body.fileNo,
							fileTitle: data.body.fileTitle,
							fileContent: data.body.fileContent,
							handleOptions: data.body.handleOptions,
							closeFlg: '1',
							closeMan: $common.getItem("userName"),
							closeTime: this.getCurrentTime(),
						};
					}
				});
			},
			getDocumentType(value){
				let name ="监理联系单";
				if (value === '02'){
					name = "监理通知单"
				}
				return name;
			},
			//表单提交
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						let _url = "/business/supervisioncontactinfo/situationUpdateButtonContactInfoProcessing";
						this.$http({
							url: this.$store.state.httpUrl + _url,
							method: "post",
							data: this.$http.adornData(this.dataForm)
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										this.$parent.getDataList();
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			}
		}
	}
</script>
<style scoped>
	.selItemInput.descAreaLg {
		width: 640px !important;
	}
</style>
